//plugins
require('owl.carousel');
require('./vendor/calendar.js');
require('chart.js');

$(document).ready(function(){
    breadcrumbs();
    breadcrumbsMobile();
    cardCarousel();
    selectOption();
    toggleJob();
    viewImage();
    scrollTo();
    featuredEvents();
    initiateCalendar();
    btn_value();
    scrollUp();
    lineGrow();
    hideDescription();
    expandTxtInput();
    keyFactsCarousel();
    landingCarousel();
    barChart();
    removeFile();
    showMore();
    customSelect();
    searchPage();
    filterFAQ();
});

$(window).resize(function() {
    cardCarousel();
    featuredEvents();
    keyFactsCarousel();
    landingCarousel();
});

// toggle job overview & application
function toggleJob(){
    $("a#overview, a#application").click(function () {
        $("a#overview, a#application").removeClass('active');
        $(this).addClass('active');

        var id = $(this).attr("id");
        $("div.content").css("display", "none");
        $("div.content#" + id + "").css("display", "block");
    });

    $(".btn-yellow").click(function(){
        $("a#overview").removeClass('active');
        $("a#application").addClass('active');
        $("div.content#overview").css("display", "none");
        $("div.content#application").css("display", "block");
    });
    $("div.content#overview").css("display", "block");
}

function customSelect(){
    $(".show-option").click(function () {
        $(".btn-border-green").removeClass('active');
        $(".show-option").addClass('active');
        $(".select-box").addClass('active');
        $('.select-option-container').toggleClass('show');
    })

    $(".select-option-container p").click(function(){
        var p_value =  $(this).text();
        $(".selected-year").text(p_value);
        $('.select-option-container').removeClass('show');
    })
}

function breadcrumbs(){
    $(".submenu-nav").click(function(){
        $(".submenu-nav").removeClass('active');
        $(this).addClass('active');
    })
}

function breadcrumbsMobile(){
    $(".breadcrumbs-main-menu").click(function(){
        $(".breadcrumbs-list").toggleClass('active');
    })
}

// carousel
function cardCarousel() {  
    if ($(window).width() < 767) {
        $('#cardCarousel , #feesCarousel').addClass("owl-carousel owl-theme");
        console.log('run');
        $('#cardCarousel, #feesCarousel').owlCarousel({ 
            loop:true,
            // autoplay:true,
            // autoplayTimeout:3000,
            // autoplayHoverPause:true,
            items: 1,
            stagePadding: 50,
            margin: 20
        });
    } else{
        $('.owl-carousel').owlCarousel('destroy');
        $('#cardCarousel, #feesCarousel').removeClass("owl-carousel owl-theme");
    }
}

function featuredEvents() {
    $('#featured-events').owlCarousel({ 
        loop: false,
        nav: true,
        dots: false,
        items: 1,
        navText : ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>'],
        responsive:{
            0:{
                items:1,
                stagePadding: 0,
                dots: true,
            },
            1024:{
                items: 1,
                stagePadding: 150,
                margin: 10,
            },
            1400:{
                items: 1,
                stagePadding: 220,
                margin: 20,
            }
        }
    });
    $(".owl-item:not(.active)").mouseover(function() {
        $(".owl-next .fa-chevron-right").addClass("active");
    });
    // $(".owl-item:not(.active)").mouseout(function() {
    //     $(".owl-next .fa-chevron-right").removeClass("active");
    // });
    // $(".owl-prev").hide();
    // $(".owl-next").hide();
}

function keyFactsCarousel(){
    $('#key-facts').owlCarousel({ 
        loop: true,
        nav: true,
        autoplay:true,
        autoplayTimeout:3000,
        dots: false,
        navText : ['<i class="icon-arrow-left"></i>','<i class="icon-arrow-right"></i>'],
        responsive:{
            0:{
                items:1,
                stagePadding: 15,
                margin: 15,
                dots:true
            },
            767:{
                items: 2,
                stagePadding: 30,
                margin: -100,
            },
            1024:{
                items: 3,
                stagePadding: 30,
                margin: -80,
            },
            1400:{
                items: 4,
                stagePadding: 60,
                margin: -100,
            }
        }
    });
}

function landingCarousel(){
    $('#banner-slider').owlCarousel({
        loop:true,
        nav: true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        items: 1,
        itemsDesktop: false,
        itemsDesktopSmall: false,
        itemsTablet: false,
        itemsMobile: false,
        dots:true,
        nav:false,
        // navText : ['<i class="fas fa-chevron-left"></i>','<i class="fas fa-chevron-right"></i>']
    });
}

// toggle select icon
function selectOption(){
    $('#faculty-type, #filter-year').click(function(){
        $('.fas.fa-arrow-down').toggleClass('active');
    });

    $('#category').click(function(){
        $('.fas.fa-arrow-down#category').toggleClass('active');
    });

    $('.form-select#title').click(function(){
        $('.fas.fa-arrow-down#title').toggleClass('active');
    });

    $('#keen_category').click(function(){
        $('.fas.fa-arrow-down#keen_category').toggleClass('active');
    });

    $('#country').click(function(){
        $('.fas.fa-arrow-down#country').toggleClass('active');
    });

    $('#contact_info').click(function(){
        $('.fas.fa-arrow-down#contact_info').toggleClass('active');
    });

    $('#children_number').click(function(){
        $('.fas.fa-arrow-down#children_number').toggleClass('active');
    });

    $('#join_program').click(function(){
        $('.fas.fa-arrow-down#join_program').toggleClass('active');
    });

    $('#grade_group').click(function(){
        $('.fas.fa-arrow-down#grade_group').toggleClass('active');
    });

    $('#admission').click(function(){
        $('.fas.fa-arrow-down#admission').toggleClass('active');
    });

    $('#hear_about').click(function(){
        $('.fas.fa-arrow-down#hear_about').toggleClass('active');
    });
}

function viewImage(){
    $('.openModal').on('click', function() {
        $('.imagepreview').attr('src', $(this).find('img').attr('src'));
        $('#imagemodal').modal('show');   
    });	
}

function scrollTo(){
    $('#jump-select').change( function(e) {
        e.preventDefault();

        var select_value = $(this).find(':selected').attr('value');
        var target_div = $(select_value);
        $('html,body').animate({
            scrollTop: ($(target_div).offset().top - 100)
        }, 800);

        // var cTarget = $(this).val();
        // window.location.hash = cTarget;
        $("#jump-select").find( 'option:first' ).prop( 'selected', true );
    });

    $(".history-box a").click(function(e){
        e.preventDefault();

        var div_id = $(this).attr("href");
        $('html,body').animate({
            scrollTop: ($(div_id).offset().top - 100)
        }, 800);
    });
}

// time value in book tour form
function btn_value(){
    $( "input:button" ).click(function() {
        $('#timeVal').val($( this ).val())
    });
}

// initiate calendar
function initiateCalendar(){
    $('.calendar-container').calendar({
        weekDayLength: 3,
        prevButton:"Prev",
        nextButton:"Next",
        highlightSelectedWeekday: true,
        onClickDate: selectDate
    });

    $('.calendar-container').updateCalendarOptions({
        date: new Date(),
        disable: function (date) { 
          return date.getDay() == 6 || date.getDay() == 0; // This will disable all dates before today
        },
    })
}

function selectDate(date) {
    var calendar = $('.calendar-container').calendar();
    $('.calendar-container').updateCalendarOptions({
      date: date
    });

    var showDate = calendar.getSelectedDate(); // get the selected date

    if (showDate != null){
        console.log(showDate);
        console.log(showDate.getDay());
        $("#date_error_message").hide();
        return true;
    } else {
        $("#date_error_message").html("Please choose date.");
		$("#date_error_message").show();
        return false;
    }
}

// function scroll up fade in & out button
function scrollUp(){
    $(".btn-scroll-top").hide();
    $(window).on("scroll ", function() {
        if ($(this).scrollTop() > 400) {
            $(".btn-scroll-top").fadeIn();
        } else {
            $(".btn-scroll-top").fadeOut();
        }
    });

    $(".btn-scroll-top").click(function() {
        $("html,body ").animate({
            scrollTop: 0
        }, 1000);
    });
}

function lineGrow(){
    var $i = $('.scroll-circle');
    var $window = $(window);
    // var offset = 205;
    var offset = 200;
    var minHeight = 500;
    
    $window.on('scroll resize', grow);
    
    function grow() {
      var scrollPercentage = 100 * $window.scrollTop() / ($('html').height() - $window.height());
      
      var maxHeight = $(window).height() - (offset * 2) - minHeight;
      
      var newHeight = ((maxHeight / 100) * scrollPercentage) + minHeight;
      
      $i.css('height', newHeight);
    }
    
}

//hide description in mobile
function hideDescription(){
    // if ($(window).width() < 767) {
    //     $('.card-body.hide-description').each(function() {
    //         var $pTag = $(this).find('p.facility-description');
    //         if($pTag.text().length > 80){
    //             var shortText = $pTag.text();
    //             shortText = shortText.substring(0, 80);
    //             $pTag.addClass('fullArticle').hide();
    //             shortText += ' <a class="read-more-link">Show more text ></a>';
    //             $pTag.append(' <a class="read-less-link">&lt; Show less text</a>');
    //             $(this).append('<p class="preview">'+shortText+'</p>');
    //         }
    //     });
        
    //     $(document).on('click', '.read-more-link', function () {
    //         $(this).parent().hide().prev().show();
    //     });
        
    //     $(document).on('click', '.read-less-link', function () {
    //         $(this).parent().hide().next().show();
    //     });
    // } else{
    //     $("p.facility-description").removeClass("fullArticle").show();
    //     $("a.read-less-link").hide();
    //     $("p.preview").hide();
    // }
    if ($(window).width() < 767) {
        $('.card-body.hide-description').each(function() {
            var $pTag = $(this).find('p.facility-description');
            if($pTag.text().length > 80){
                var shortText = $pTag.text();
                shortText = shortText.substring(0, 80);
                $pTag.addClass('fullArticle').hide();
                shortText += ' <a class="read-more-link">Show more text ></a>';
                $pTag.append(' <a class="read-less-link">&lt; Show less text</a>');
                $(this).append('<p class="preview">'+shortText+'</p>');
            }
        });
        
        $(document).on('click', '.read-more-link', function () {
            $(this).parent().hide().prev().show();
        });
        
        $(document).on('click', '.read-less-link', function () {
            $(this).parent().hide().next().show();
        });
    }
}

// hide show search div
function expandTxtInput(){
    $('#search_input').focus(function(){
        $('.search-terms').addClass('active');
        $('.button-container').hide();
        $('.navbar-collapse p').hide();
        $('a.d-flex p.d-none.d-lg-block').attr("style", "display:none !important");
        $('ul.hide-element').hide();
        $('a.close-search').addClass('active');
    }).blur(function(){
        $('.search-terms').removeClass('active');
        $('.button-container').show();
        $('.navbar-collapse p').show();
        $('a.d-flex p.d-none.d-lg-block').attr("style", "display:block");
        $('ul.hide-element').show();
        $('a.close-search').removeClass('active');
        $('p.close').hide();
    })
}

function removeFile(){
    $("#file-delete").hide();
    $("#file-upload").change(function(){
        // $("#file-name").text(this.files[0].name);

        if ($("#file-upload").val() != "") {
            $("#file-delete").attr("style", "display:block");
            $("#upload-file").replaceWith('<label for="file-upload" class="btn btn-upload active" id="file-uploaded"><span class="icon-attachment me-2"></span>Resume & CV uploaded</label>')
          } else {
            $("#file-delete").attr("style", "display:none");
        }
    });
    $("#file-delete").click(function() {
        $("#file-upload").val('');
        $("#file-uploaded").replaceWith('<label for="file-upload" class="btn btn-upload" id="upload-file"><span class="icon-attachment me-2"></span>Attach Resume/CV</label>');
        $("#file-delete").attr("style", "display:none");
    })
}

//chart
function barChart(){
    var ctx = $('#passRateChart');
    var passRateChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: [''],
            datasets: [{
                label: 'Mkis',
                data: [100],
                backgroundColor: 'rgba(51, 180, 117, 1)',
                borderRadius: 10,
                borderSkipped: false,
                barPercentage: 0.8,
                barThickness: 'flex',
                categoryPercentage:0.3

              },
              {
                label: 'Global',
                data: [89],
                backgroundColor: 'rgba(222, 248, 220, 1)',
                borderRadius: 10,
                borderSkipped: false,
                barPercentage: 0.8,
                barThickness: 'flex',
                categoryPercentage:0.3

            }]
        },
        options: {
            responsive: true,
            scales :{
                y: {
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 20
                    }
                  }
            }
        }
    });

    var ctx = $('#averageScore');
    var averageScore = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: [''],
            datasets: [{
                label: 'Mkis',
                data: [45],
                backgroundColor: 'rgba(51, 180, 117, 1)',
                borderRadius: 10,
                borderSkipped: false,
                barPercentage: 0.8,
                barThickness: 'flex',
                categoryPercentage:0.3

              },
              {
                label: 'Global',
                data: [38],
                backgroundColor: 'rgba(222, 248, 220, 1)',
                borderRadius: 10,
                borderSkipped: false,
                barPercentage: 0.8,
                barThickness: 'flex',
                categoryPercentage:0.3

            }]
        },
        options: {
            responsive: true,
            scales :{
                y: {
                    min: 0,
                    max: 50,
                    ticks: {
                        stepSize: 10
                    }
                }
            }
        }
    });
}

// display more university list
function showMore(){
    $(".university-list").children().eq(1).hide();
    $("a.read-less").hide();
    $("a.read-more").click(function(){
        $(".university-list").children().eq(1).show();
        $("a.read-less").show();
        $("a.read-more").hide();
    })

    $("a.read-less").click(function(){
        $(".university-list").children().eq(1).hide();
        $("a.read-less").hide();
        $("a.read-more").show();
    })
}

function searchPage(){
    $(".search-bg .btn-border-green").click(function(){
        $(".btn-border-green").addClass('active');
    })

    $("#search-page").focus(function(){
        $(".btn-border-green").removeClass('active');
    })

    // $("#clear-text").click(function(){
    //     $('input[type="text"]#search-page').val('');
    // })
    $('input.deletable').wrap('<span class="deleteicon"></span>').after($('<span>x</span>').click(function() {
        $(this).prev('input').val('').trigger('change').focus();
    }));
}

function filterFAQ(){
    $(".btn-border-green").click(function (e) {
        e.preventDefault();

        $(".btn-border-green").removeClass('active');
        $(this).addClass('active');

        var div_id = $(this).attr("href");
        $('html,body').animate({
            scrollTop: ($(div_id).offset().top - 100)
        }, 800);
    });
}